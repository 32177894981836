body {
  font-family: 'Montserrat', sans-serif;
}
h1 {
  font-weight: 900;
  margin: 0;
}
p {
  font-weight: 400;
}
strong {
  font-weight: 700;
}
.App, #root, body, html {
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}
.Apps {
  height: 100%;
  width: 100%;
}

.topView {
  position: fixed;
  z-index: 9999;
  width: 0;
  height: 0;
}
.content-wrapper  {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  overflow-y: hidden;
}
/* TOP VIEW */

.logo {
  position: fixed;
  width: 40%;
  left: 30%;
  top: 25%;
}
#prev {
  position: fixed;
  left: 3%;
  top: 49%;
}
#next {
  position: fixed;
  left: 97%;
  top: 49%;
}
#down {
  position: fixed;
  left: 50%;
  top: 95%;
}
#up {
  position: fixed;
  left: 50%;
  top: 2%;
}

/*BACKGROUND VIDEO*/

.bigVideo {
  height: 100%;
  width: 100%;
}
.greenVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fig {
  position: absolute;
  margin: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*BACKGROUNDS*/

.case-main, .whatwedo, .landing, .whatwedo-main,
.whatwedo-two, .whatwedo-three, .case-two, .case-three,
.contact, .case-four, .cases, .case-five {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  position: relative;
}
.case-three, .case-two, .case-four {
  background-color: #50b5e3;
  background-repeat: no-repeat;
  background-size: cover;
}
.case-three {
  background-image: url('./media/img/case1.png');
  background-position: right;
  flex-direction: column;
}
.case-two {
  background-image: url('./media/img/case3.jpg');
  flex-direction: column;
}
.case-four {
  background-image: url('./media/img/case4.jpg');
  background-position: center;
}
.whatwedo-three {
  background-color: #f88f1c;
}
.whatwedo-two {
  background-color: #bd10e0;
}
.whatwedo-main {
  background-color: #7ed321;
}
.case-main, .case-five {
  background-color: #50b5e3;
}
.contact {
  background-color: #ff008d
}
.landing {
  overflow: hidden;
  background-color: white;
}


/* LANDING PAGE*/
.figc {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.textContainer {
  width: 40%;
}
.textContainer h6 {
  text-align: center;
  color: white;
  font-weight: 400;
  font-size: 2.5rem;
  margin: 5% 0 5% 0;
}
.textContainer p {
  text-align: center;
  color: white;
  font-weight: 400;
  font-size: 2rem;
  margin: 0;
}
#nextMobile img {
  display: none;
}


/*WHAT WE DO */
.whatwedo-main, .whatwedo-two, .whatwedo-three {
  flex-direction: column;
  color: white;
}
.whatwedo-main h1 {
  width: 85%;
  font-size: 7.5rem;
}
.whatwedo-main div {
  width: 85%;
  font-size: 2rem;
}
.whatwedo-main div p {
  width: 57%;
}
.whatwedo-main .p4 {
  display: none;
}


.whatwedo-two h1 {
  width: 85%;
  font-size: 5.5rem;
  margin-bottom: 2%;
  padding-right: 32%;
  box-sizing: border-box;
}
.whatwedo-two p {
  width: 85%;
  font-size: 2rem;
  padding-right: 35%;
  box-sizing: border-box;
  margin: 0;
}
.whatwedo-two .p2 {
  display: none;
}
.whatwedo-two h2 {
  width: 85%;
  font-size: 3rem;
  margin: 2% 0;
}

.whatwedo-three h1 {
  width: 85%;
  font-size: 7rem;
  font-weight: 900;
  box-sizing: border-box;
}
.whatwedo-three p {
  width: 85%;
  font-size: 2rem;
  box-sizing: border-box;
  padding-right: 40%;
  margin: 2% 0 0 0;
}
.whatwedo-three .p3 {
  display: none;
}

/*CASES */

.case-main, .case-five {
  flex-direction: column;
  color: white;
}
.case-main h1{
  width: 85%;
  font-size: 7rem;
}
.case-main p{
  width: 85%;
  font-size: 2rem;
  box-sizing: border-box;
  padding-right: 35%;
  margin: 3% 0 0 0;
}
.case-main .p3{
  display: none;
}

.case-two .h1-container h1 {
  color: white;
  background-color: #ff008d;
}
.case-four {
  flex-direction: column;
}
/* CONTACT */
.contact {
  flex-direction: column;
  color: white;
}
.contact h1 {
  width: 85%;
  font-size: 7rem;
}
.contact .p1 {
  width: 85%;
  font-size: 2rem;
  margin: 2% 0 0 0;
}
.contact .h2-1 {
  width: 85%;
  font-size: 3rem;
  margin: 0;
  font-weight: 700;
}
.contact .p2 {
  width: 85%;
  font-size: 2rem;
  margin: 4% 0 0 0;
}
.contact .h2-2 {
  width: 85%;
  font-size: 4rem;
  margin: 0;
  font-weight: 700;
}
/*ANIMATIONS */
/*SCROLL RIGHT */
.slide-enter {
  left: 100%;
  z-index: 1;
}
.slide-enter.slide-enter-active {
  left: 0;
  transition: 600ms linear;
}
.slide-exit {
  bottom: 100%;
  left: 0;
}
.slide-exit.slide-exit-active {
  left: -100%;
  bottom: 100%;
  transition: 600ms linear;
}

/*SCROLL LEFT */
.sliderev-enter {
  left: -100%;
  z-index: 1;
}
.sliderev-enter.sliderev-enter-active {
  left: 0;
  transition: 600ms linear;
}
.sliderev-exit {
  bottom: 100%;
  left: 0;
}
.sliderev-exit.sliderev-exit-active {
  left: 100%;
  bottom: 100%;
  transition: 600ms linear;
}

/*SCROLL DOWN */
.down-exit {
  bottom: 100%;
}
.down-exit.down-exit-active {
  bottom: 200%;
  transition: 600ms linear;
}
.down-enter {
  bottom: -100%;
  z-index: 1;
}
.down-enter.down-enter-active {
  bottom: 0;
  transition: 600ms linear;
}

/*SCROLL UP */
.up-exit {
  bottom: 100%;
}
.up-exit.up-exit-active {
  bottom: 0;
  transition: 600ms linear;
}
.up-enter {
  bottom: 100%;
  z-index: 1;
}
.up-enter.up-enter-active {
  bottom: 0;
  transition: 600ms linear;
}

/* LOGO ANIMATIONS AKA LOGOMATIONS*/
@keyframes notfirst {
    from {
      width: 40%;
      left: 30%;
      top: 25%;
    }
    to {
      width: 20%;
      left: 4%;
      top: 4%;
    }
}
#otherViews {
  width: 20%;
  left: 4%;
  top: 4%;
  animation-name: notfirst;
  animation-duration: 600ms;
}

@keyframes first {
    0% {
      opacity: 1;
      width: 20%;
      left: 4%;
      top: 4%;
    }
    49% {
      opacity: 0;
      width: 20%;
      left: -40%;
      top: 4%;
    }
    50% {
      opacity: 0;
      width: 40%;
      left: 30%;
      top: 25%;
    }
    100% {
      opacity: 1;
      opacity: 0;
      width: 40%;
      left: 30%;
      top: 25%;
    }
}
#firstView {
  animation-name: first;
  animation-duration: 1400ms;
}

/* ARROW ANIMATIONS AKA NAVMATIONS */
.navMation {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
  box-shadow: 0 0 0 rgba(204,169,44, 0.4);
  animation: 1s ease 2s 2 normal none running pulse;
  background: none;
  border: none;
  padding: 0;
}

.navMationBlack {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
  box-shadow: 0 0 0 rgba(204,169,44, 0.4);
  animation: 1s ease 2s 2 normal none running pulseBlack;
  background: none;
  border: none;
  padding: 0;
}
.navMationMobile {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  box-shadow: 0 0 0 rgba(204,169,44, 0.4);
  animation: 1s ease 8s 2 normal none running pulse;
  background: none;
  border: none;
  display: none;
  padding: 0;
}

@keyframes pulse {
  0% {
    background-color: rgba(246, 246, 246, 0.8);
    -moz-box-shadow: 0 0 0 0 rgba(246, 246, 246, 0.8);
    box-shadow: 0 0 0 0 rgba(246, 246, 246, 0.8);
  }
  70% {
    background-color: rgba(204,169,44, 0);
    -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
    background-color: rgba(204,169,44, 0);
    -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulseBlack {
  0% {
    background-color: rgba(0, 0, 0, 0.8);
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.8);
  }
  70% {
    background-color: rgba(204,169,44, 0);
    -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
    background-color: rgba(204,169,44, 0);
    -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
/* media screens */
@media only screen and (max-height: 910px) {
  .whatwedo-main h1, .whatwedo-two h1, .whatwedo-three h1, .case-main h1 {
    font-size: 5rem;
  }
  .whatwedo-main div, .whatwedo-two p{
    font-size: 1.5rem;
  }
}
@media only screen and (max-height: 700px ) {
  .textContainer h6 {
    margin-top: 34%;
  }
  .whatwedo-main h1 {
    font-size: 3.5rem;
  }
  .whatwedo-two h1 {
    font-size: 3.5rem;
  }
  .whatwedo-two h2 {
    font-size: 2rem;
    margin: 1% 0 1% 0;
  }
  .whatwedo-three p, .case-main p {
    font-size: 1.5rem;
  }
  .contact h1 {
    font-size: 4.5rem;
  }
  .contact .p1, .contact .p2 {
    margin: 1% 0 0 0;
  }
}
@media only screen and (max-height: 640px) {
  .whatwedo-main h1 {
    line-height: 0.8;
  }
  .whatwedo-main div p {
    margin: 1% 0 1% 0;
  }
}
@media only screen and (max-height: 560px) {
  .whatwedo-main div {
    font-size: 1rem;
  }
  .whatwedo-two h1 {
    font-size: 2rem;
    margin: 0 0 1% 0;
  }
  .whatwedo-two h2 {
    font-size: 1.5rem;
    margin: 1% 0 0 0;
  }
  .whatwedo-three h1, .case-main h1 {
    font-size: 3.5rem;
  }
  .whatwedo-three p, .case-main p {
    margin: 1% 0 0 0;
  }
  .contact h1 {
    font-size: 2rem;
  }
  .contact .p1, .contact .p2 {
    font-size: 1.3rem;
  }
  .contact .h2-1, .contact .h2-2 {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 1400px) {
  .textContainer {
    width: 60%;
  }
  .whatwedo-main h1 {
    font-size: 6rem;
  }
  .whatwedo-main div p {
    width: 70%;
  }
}
@media only screen and (max-width: 1045px) {


  .whatwedo-main h1 {
    font-size: 5rem;
  }
  .whatwedo-main div p {
    width: 90%;
  }
  .whatwedo-two h1 {
    font-size: 4rem;
    padding-right: 0;
  }
  .whatwedo-two p {
    padding-right: 20%;
  }
  .whatwedo-three h1 {
    font-size: 6rem;
  }
  .whatwedo-three p {
    padding-right: 20%;
  }
  .case-main h1{
    font-size: 6rem;
  }
  .contact .h2-2 {
    font-size: 2.5rem;
  }
}
@media only screen and (max-width: 865px) {
  .whatwedo-main h1 {
    font-size: 4rem;
  }
  .whatwedo-two h1 {
    font-size: 3rem;
  }
  .whatwedo-two p {
    padding-right: 0;
  }
  .whatwedo-two h2 {
    font-size: 2.4rem;
  }
  .whatwedo-three h1 {
    font-size: 5rem;
  }
  .whatwedo-three p {
    padding-right: 0;
  }
  .case-main h1{
    width: 85%;
    font-size: 5rem;
  }
  .case-main p{
    padding-right: 0;
  }

  .contact h1 {
    font-size: 5rem;
  }
  .contact .p1 {
    font-size: 1.5rem;
  }
  .contact .h2-1 {
    font-size: 2.5rem;
  }
  .contact .p2 {
    font-size: 1.5rem;
  }

}
@media only screen and (max-width: 691px) {
  .whatwedo-main div {
    font-size: 1.5rem;
  }
  .whatwedo-three h1 {
    font-size: 4rem;
  }
  .whatwedo-three p {
    font-size: 1.5rem;
  }
  .case-main p{
    font-size: 1.5rem;
  }
  .contact h1 {
    font-size: 4rem;
  }
  .contact .p1 {
    font-size: 1.5rem;
  }
  .contact .h2-1 {
    font-size: 2rem;
  }
  .contact .p2 {
    font-size: 1.5rem;
  }
  .contact .h2-2 {
    font-size: 2rem;
  }
  .textContainer {
    width: 90%;
  }
}

@media only screen and (max-width: 455px) {
  /*TOP VIEW */
  .logo {
    width: 80%;
    left: 10%;
    top: 30%;
  }
  #prev img, #next img, #up img, #down img, .navMation, .navMationBlack {
    display: none;
  }
  #nextMobile img, .navMationMobile {
    display: block;
  }

  /* LANDING PAGE */
  .textContainer {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .textContainer h6 {
    margin-bottom: 5%;
  }
  .textContainer p, .textContainer h6  {
    font-size: 1rem;
  }
  .textContainer img {
    margin-top: 9%;
  }
  /*What We Do Main */

  .whatwedo-main h1 {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    line-height: 0.9;
    font-size: 2.8rem;
    width: 80%;
    height:40%;
    margin-bottom: 3%;
    text-align: left;
  }
  .whatwedo-main div {
    width: 80%;
    height: 50%;
  }
  .whatwedo-main div p {
    width: 100%;
    font-size: 1rem;
    text-align: left;
    margin: 0 0 3% 0;
  }
  .whatwedo-main .p4 {
    display: block;
  }

  /* What we do Two*/
  .whatwedo-two h1 {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    line-height: 0.9;
    font-size: 2.5rem;
    width: 80%;
    height:35%;
    margin-bottom: 5%;
    text-align: left;
  }
  .whatwedo-two h2 {
    margin: 0 0 5% 0;
    font-size: 1.5rem;
    height: 21%;
    width: 80%;
    text-align: left;
  }
  .whatwedo-two .p1 {
    width: 80%;
    height: 25%;
    font-size: 1rem;
    text-align: left;
    margin: 0 0 5% 0;
  }
  .whatwedo-two .p2 {
    display: block;
    width: 80%;
    height: 10%;
    font-size: 1rem;
    text-align: left;
    margin: 0 0 0 0;
  }
  /*What we do Three */
  .whatwedo-three h1 {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    line-height: 1;
    font-size: 2.5rem;
    width: 80%;
    height:35%;
    margin-bottom: 3%;
    text-align: left;
  }
  .whatwedo-three .p1 {
    width: 80%;
    height: 25%;
    margin: 0 0 5% 0;
    font-size: 1em;
    text-align: left;
  }
  .whatwedo-three .p2 {
    width: 80%;
    height: 15%;
    margin: 0 0 5% 0;
    font-size: 1em;
    text-align: left;
  }
  .whatwedo-three .p3 {
    display: block;
    width: 80%;
    height: 10%;
    margin: 0 0 3% 0;
    font-size: 1em;
    text-align: left;
  }

  /*Client Work Main */
  .case-main h1, .case-five h1 {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    line-height: 1;
    font-size: 3rem;
    width: 80%;
    height:30%;
    margin-bottom: 3%;
    text-align: left;
  }
  .case-five p {
    width: 80%;
    height: 45%;
    font-size: 1rem;
    text-align: left;
  }
  .case-main .p1{
    width: 80%;
    height: 17%;
    font-size: 1rem;
    text-align: left;
    margin: 0 0 3% 0;
  }
  .case-main .p2{
    width: 80%;
    height: 17%;
    font-size: 1rem;
    text-align: left;
    margin: 0 0 3% 0;
  }
  .case-main .p3{
    display: block;
    width: 80%;
    height: 10%;
    font-size: 1rem;
    text-align: left;
    margin: 0 0 3% 0;
  }
  /* Client Work Two */
  .case-two .h1-container {
    width: 80%;
    height: 73%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
  }
  .case-two .h1-container h1 {
    color: white;
    background-color: #ff008d;
    padding: 0% 5%;
    margin-bottom: 1%;
    border-radius: 5%;
  }
  .case-two p {
    width: 80%;
    height: 13%;
    display: flex;
    align-items: center;
    margin: 4% 0;
  }
  .case-two .img-container {
    width: 80%;
    height: 10%;
  }
  .case-two .img-container img {
    width: 35%;
  }
  /*Client Work Three */
  .case-three .img-container {
    width: 80%;
    height: 56%;
    display: flex;
    align-items: flex-end;
  }
  .case-three .img-container img {
    width: 30%;
  }
  .case-three h1 {
    width: 80%;
    height: 15%;
    line-height: 0.9;
    font-size: 2.5rem;
    margin: 4% 0;
  }
  .case-three p {
    width: 80%;
    height: 28%;
    margin-bottom: 8%;
  }
  /*Client Work Four */
  .case-four .img-container {
    width: 80%;
    height: 36%;
    display: flex;
    align-items: flex-end;
    margin-bottom: 4%;
  }
  .case-four .img-container img {
    width: 50%;
  }
  .case-four .h1-container {
    width: 80%;
    height: 17%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
    margin: 4% 0;
  }
  .case-four .h1-container h1 {
    color: white;
    background-color: #ff008d;
    padding: 0% 5%;
    margin-bottom: 1%;
    border-radius: 5%;
    font-size: 2.5rem;
  }
  .case-four p {
    width: 80%;
    height: 40%;
    color: white;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  }
  /*Contact */
  .contact h1 {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    line-height: 0.9;
    font-size: 2.5rem;
    width: 80%;
    height: 35%;
    margin: 0;
  }
  .contact .p1 {
    display: flex;
    width: 80%;
    height: 10%;
    margin: 0;
    align-items: flex-end;
  }
  .contact .p2 {
    display: flex;
    width: 80%;
    height: 10%;
    margin: 0;
    align-items: flex-end;
  }
  .contact .h2-1 {
    width: 80%;
    height: 10%;
    margin: 0;
    font-weight: 900;
  }
  .contact .h2-2 {
    width: 80%;
    height: 40%;
    font-weight: 900;
    margin: 0;
    font-size: 1.2rem;
  }
  /* LOGO ANIMATIONS AKA LOGOMATIONS*/
  @keyframes mobileOther {
      from {
        width: 80%;
        left: 10%;
        top: 30%;
      }
      to {
        width: 40%;
        left: 10%;
        top: 5%;
      }
  }
  #otherViews {
    width: 40%;
    left: 10%;
    top: 5%;
    animation-name: mobileOther;
    animation-duration: 600ms;
  }

  @keyframes mobileFirst {
      0% {
        opacity: 1;
        width: 40%;
        left: 10%;
        top: 5%;
      }
      49% {
        opacity: 0;
        width: 40%;
        left: -60%;
        top: 5%;
      }
      50% {
        opacity: 0;
        width: 80%;
        left: 10%;
        top: 30%;
      }
      100% {
        opacity: 1;
        opacity: 0;
        width: 80%;
        left: 10%;
        top: 30%;
      }
  }
  #firstView {
    animation-name: mobileFirst;
    animation-duration: 1400ms;
  }

}
@media only screen and (max-width: 330px) {
  .whatwedo-main h1 {
    font-size: 2rem;
    height: 15%;
  }
  .whatwedo-two h1 {
    font-size: 2rem;
    height: 25%;
  }
  .whatwedo-two .p1 {
    height: 35%;
  }
  .whatwedo-two h2 {
    font-size: 1rem;
    height: 10%;
  }
  .whatwedo-three h1 {
    font-size: 1.5rem;
    height: 17%;
  }
  .whatwedo-three .p1 {
    height: 38%;
    margin: 0;
  }
  .whatwedo-three .p2 {
    height: 28%;
    margin: 0;
  }
  .whatwedo-three .p2 {
    height: 23%;
    margin: 0;
  }
  .case-main h1 {
    font-size: 2rem;
    height: 15%;
  }
  .case-main .p1 {
    height: 27%
  }
  .case-main .p2 {
    height: 22%
  }
  .contact h1 {
    font-size: 2rem;
    height: 17%;
  }
  .contact .h2-1 {
    font-size: 1.5rem;
  }
  .contact .h2-2 {
    height: 25%;
  }
}
